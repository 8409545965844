import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ProjectInfo, projectExample } from './project.const';
import { employees } from '@pmo/teams/team/team.const'
import swal from 'sweetalert2';
import { Swal2Loader } from '@consts/swal2-loader';


import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Breadcrumb, CRUMBS } from '@components/breadcrumb/breadcrumb.conts';
import { OportunityComponent } from '../../../oportunity/oportunity.component';
import { OportunityService } from 'src/app/services/oportunity/oportunity.service';
import { log } from 'console';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @ViewChild('overviewTemp', { read: ElementRef }) overviewElement!: ElementRef;
  @ViewChild('progressTemp', { read: ElementRef }) progressElement!: ElementRef;
  @ViewChild('aboutTemp', { read: ElementRef }) aboutElement!: ElementRef;
  @ViewChild('dependenciesTemp', { read: ElementRef }) dependenciesElement!: ElementRef;
  @ViewChild('workPlanTemp', { read: ElementRef }) workPlanElement!: ElementRef;
  @ViewChild('risksTemp', { read: ElementRef }) risksElement!: ElementRef;
  @ViewChild('additionalTasksTemp', { read: ElementRef }) additionalTasksElement!: ElementRef;
  @ViewChild('loaderTemplate') loaderDialog!: any;
  public project: ProjectInfo = projectExample;
  tmRole: any;
  dataProyect:any;
  public employees = employees.slice();
  configuredData:any = {};
  private readonly pdfMargin = 7; // mm
  dataId:any;
  public breadcrumb: Breadcrumb[];
  commentsExample ={
    id: '',
    type: 'oportunity',
    comments: [],
    idUser: sessionStorage.getItem('idEmp')
  };

  ngOnInit(): void {    
    this.tmRole = sessionStorage.getItem('timeManagementRole');
      this.breadcrumb = [
        CRUMBS.proyectLeader,
      ] 
  }


 
  constructor(private dialog: MatDialog,
    private oportunityService: OportunityService
  ) {
    this.dataId = JSON.parse(localStorage.getItem('activeProyectStorage'));
    this.oportunityService.getOportunityById(this.dataId?._id).subscribe((response) => {
      this.calculateProgress(response.oportunity.dateInitProyect,response.oportunity.dateEndProyect,response.hours);
      this.dataProyect = response.oportunity;
      this.breadcrumb.push({
        title: response.oportunity.name,
        route: CRUMBS.pmoProject.route
      })
      let overview = {
        leaderAvatar: response.oportunity.responsable2bcore,
        sprint: response.oportunity.sprint,
        status: response.oportunity.statusOportunity,
        team: response.oportunity.authorizedStaff,
        title: response.oportunity.name,
        updatedAt: response.oportunity.updatedAt
      }
      this.configuredData.overview = overview;
      let about = {
        client: response.oportunity.client,
        code: response.oportunity.code,
        description: response.oportunity.proyectDescription,
        objectives: response.oportunity.objectives,
        methodology: response.oportunity.metodology
      }
      this.configuredData.about = about;
    
      let dependendencies = [];
      response.oportunity.dependencies.forEach((dependencie) => {
        let data = {
          description: dependencie.dependency,
          inChargeAvatar: dependencie.idResponsable,
          status: dependencie.status,
          commitmentAt: dependencie.deliveryDate,
          createdAt: dependencie.date
      }
      dependendencies.push(data)
      })
      let workPlan = [];
      response.oportunity.activities.forEach((act) => {
       let dataUpdated = {
          order: act.order,
          activity: act.activity,
          startDate:act.initDate,
          endDate: act.endDate,
          color: act.color
        }
        workPlan.push(dataUpdated)
      })
      let aditionalTasks = [];
      response.oportunity.aditionalActivities.forEach((act) => {
        let dataUpdated = {
          status: act.status,
           title: act.activity,
         }
         aditionalTasks.push(dataUpdated)
       })
       let risks = [];
       response.oportunity.risks.forEach((act) => {
        let dataUpdated = {
          status: act.status,
           title: act.activity,
         }
         risks.push(dataUpdated)
       })
       this.configuredData.risks = risks;
      this.configuredData.additionalTasks = aditionalTasks;
      this.configuredData.workPlan = workPlan;
      this.configuredData.dependencies = dependendencies;

      this.commentsExample = {
        id: this.dataId?._id,
        type: 'oportunity',
        comments: response.oportunity.comments ? response.oportunity.comments : [],
        idUser: sessionStorage.getItem('idEmp')
      }
    })
  }

  public async downloadReport() {
    const metaElenent = document.querySelector('meta[name="viewport"]');
    const originalViewport = metaElenent?.getAttribute("content") ?? 'width=device-width, initial-scale=1';
    if (window.innerWidth < 1200 && metaElenent) {
      metaElenent.setAttribute("content", "width=1300px, user-scalable=no");
    }
  
    swal.fire(Swal2Loader);
    this.accordion.openAll();

    try {
      let pdf = new jsPDF('p', 'mm', 'letter');

      const overviewCanvas = await this.getCanvas(this.overviewElement);
      const progressCanvas = await this.getCanvas(this.progressElement);
      const aboutCanvas = await this.getCanvas(this.aboutElement);
      pdf = this.addPageToPdf(pdf, [overviewCanvas, progressCanvas, aboutCanvas])

      pdf.addPage()
      const dependenciesCanvas = await this.getCanvas(this.dependenciesElement);
      const workPlanCanvas = await this.getCanvas(this.workPlanElement);
      pdf = this.addPageToPdf(pdf, [dependenciesCanvas, workPlanCanvas])

      pdf.addPage()
      const risksCanvas = await this.getCanvas(this.risksElement);
      const additionalTasksCanvas = await this.getCanvas(this.additionalTasksElement);
      pdf = this.addPageToPdf(pdf, [risksCanvas, additionalTasksCanvas])

      this.addExtras(pdf);
      
      pdf.save(`reporte-${this.project.overview.updatedAt.toISOString()}.pdf`);

      swal.close()
    } catch (error) {} finally {
      if (metaElenent) {
        metaElenent.setAttribute("content", originalViewport);
      }
    }
  }
 
  private async getCanvas(element: ElementRef) {
    return html2canvas(element.nativeElement, {
      scale: 3,
      useCORS: true,
      allowTaint: false,
      windowWidth: 1800,
      ignoreElements: (element) => {
        if (element.id == 'buttonFullscreen') return true;
        if (element.classList.contains('toggle-icon')) return true;
        if (element.id == 'dependenciesMenu') return true;
        return false;
      }
    })
  }
  calculateProgress(initDate:any, endDate:any, currentHours:any) {
    let numberHours = currentHours[0]?.totalHours ?? 0;
    let totalHoursProyect = this.getWorkingDays(initDate,endDate)*8;
    let proyectedHours = this.getDaysInitToday(initDate)*8;
    let proyectedProgress =(proyectedHours / totalHoursProyect) * 100;
    let realProgress =(numberHours / totalHoursProyect) * 100;
    this.configuredData.progress ??= {}; // Inicializa progress si es undefined
    this.configuredData.progress.projection = Math.round(proyectedProgress)  ?? 0; // Si es undefined, asigna 0
    this.configuredData.progress.realProgress = Math.round(realProgress)  ?? 0; 
  }
   getWorkingDays(startDate: Date, endDate: Date): number {
    let count = 0; 
    let currentDate = new Date(startDate); 
    currentDate.setHours(0, 0, 0, 0); 
    const end = new Date(endDate);
    end.setHours(0, 0, 0, 0);
    while (currentDate <= end) {
      const dayOfWeek = currentDate.getDay(); 
      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        count++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return count;
  }
  
  getDaysInitToday(startDate: Date): number {
    const today = new Date(); 
    today.setHours(0, 0, 0, 0); 
  
    let count = 0;
    let currentDate = new Date(startDate);
    currentDate.setHours(0, 0, 0, 0);
  
    while (currentDate <= today) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        count++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return count;
  }

  private addPageToPdf(pdf: jsPDF, canvasToInclude: HTMLCanvasElement[]) {
    const fileWidth = 200;
    let position = 25;

    canvasToInclude.forEach((canvas) => {
      const image = canvas.toDataURL('image/jpeg');
      const imageHeight = (canvas.height * fileWidth) / canvas.width;
      pdf.addImage(image, 'JPEG', this.pdfMargin, position, fileWidth, imageHeight);
      // pdf.html()
      position += imageHeight + 10;
    })

    return pdf;
  }

  private addExtras(doc) {
    doc.setTextColor('#01173c');
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        `Página ${i} de ${pageCount}`,
        doc.internal.pageSize.width - this.pdfMargin,
        doc.internal.pageSize.height - this.pdfMargin,
        { align: 'right' }
      );

      const img = new Image();
      img.src = 'assets/logo/2BCORE_2024_VF_Horizontal.jpg';
      doc.addImage(img, 'jpg', this.pdfMargin, this.pdfMargin, 35.9, 11.94);
    }
  }

  //función para redirecciòn a flujo de edicion de detalle de proyecto
  goToEdit() {
    localStorage.setItem('activeProyect',JSON.stringify(this.project))

  }
}
