import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { activities, Activity, defaultNoAct, defaultActArea, defaultManageTeams, defaultAlianzas, defaultCursos } from '../record/record.conts';
import { Breadcrumb, CRUMBS } from '@components/breadcrumb/breadcrumb.conts';
import { ConfirmComponent, ConfirmDialogData } from '@components/confirm/confirm.component';
import { Comment } from '@pmo/projects/project/project.const';
import { leaders } from '@pmo/teams/team/team.const';
import { TimeService } from 'src/app/services/time/time.service';
import { OportunityService } from 'src/app/services/oportunity/oportunity.service';
import { LogService } from 'src/app/services/log/log.service';
import { isFuture, isSameDay } from 'date-fns';
import { INCIDENCES } from '../root/incidence/incidences.const';

@Component({
  selector: 'app-register-hours',
  templateUrl: './register-hours.component.html',
  styleUrls: ['./register-hours.component.scss']
})

export class RegisterHoursComponent {
  public registryForm!: FormGroup;
  public activityDate = new Date();
  tmRole: any;
  public timeAccumulated = {
    hours: 0,
    minutes: 0
  }
  idUser = '';
  public catActivities = []
  public types = [] // ['Proyecto 1', 'Proyecto 2', 'Proyecto 3', 'Proyecto 3'];
  public auxtTypes = []
  public readonly breadcrumb: Breadcrumb[] = [
    CRUMBS.colaboratorRegisterActivities
  ];
  isActivitySelected: any = false;
  public activities: Activity[] = []
  public displayedColumns: string[] = ['date', 'activity', 'type', 'duration', 'description', 'status', 'options'];
  public activitiesDataSource!: MatTableDataSource<Activity>;
  public isEdit: boolean = false;
  commentsExample = {
    id: '',
    type: 'activity',
    comments: [],
    idUser: sessionStorage.getItem('idEmp')
  };
  // Mobile
  public currentCard = 0;
  public card: Activity | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private timeService: TimeService,
    private oportunityService: OportunityService,
    private logService: LogService
  ) { }

  ngOnInit(): void {
    this.evaluateTime();

    this.idUser = sessionStorage.getItem('idEmp')
    this.registryForm = this.formBuilder.group({
      activity: [
        null,
        Validators.required
      ],
      code: [
        null,
        Validators.required
      ],
      mins: [
        0,
        Validators.compose([Validators.required, Validators.min(0), Validators.max(59)])
      ],
      hours: [
        0,
        Validators.compose([Validators.required, Validators.min(0), Validators.max(24)])
      ],
      description: [
        '',
        {
          updateOn: 'blur',
          validators: Validators.compose([Validators.required, Validators.minLength(56), Validators.maxLength(112)])
        }
      ],
      status: [
        ''
      ]
    }, { validators: this.validHoursMinutes() });

    this.loadTableActivities();

    const activityId = this.route.snapshot.paramMap.get('id');
    if (activityId) this.loadActivityForm(activityId);

    // Cambiar crumb
    let crumb = CRUMBS.colaboratorRegisterActivity;
    if (activityId) {
      crumb = { ...CRUMBS.colaboratorUpdateActivity };
      crumb.route += `/${activityId}`;
    }
    this.breadcrumb.push(crumb);

    this.timeService.getTimes().subscribe(data => {
      this.tmRole = sessionStorage.getItem('timeManagementRole');
      console.log(this.tmRole);

      if (this.tmRole === 'leader' || this.tmRole === 'proyectLeader') {
        this.catActivities = data?.filter(objeto => !objeto.name.includes('INCIDENCIA'));

      } else {
        let excludeKeywords = [
          'ORDINARIO: GESTIÓN DE EQUIPOS(LÍDERES)',
          'ORDINARIO: OPERACIÓN DE AREA',
          'INCIDENCIA'
        ]
        this.catActivities = data.filter(objeto =>
          !excludeKeywords.some(keyword => objeto.name.includes(keyword)))
      }

      this.catActivities.sort((a, b) => a.name.localeCompare(b.name));

    })

    this.oportunityService.getOportunitiesByEmployee(this.idUser).subscribe(data => {
      this.auxtTypes = data;
    })

  }
  validateTimeOptions(event) {
    switch (event) {
      case 'ORDINARIO: ACTIVIDADES ALIANZAS TECNOLÓGICAS':
        this.types = [...defaultAlianzas]
        break;

      case 'ORDINARIO: APOYO A PROPUESTA COMERCIAL':
        let filteredProposal = this.auxtTypes.filter(element => element.time === event);
        this.types = [...filteredProposal,]
        break;

        case ' ORDINARIO: ASIGNACIÓN CON CLIENTE':
          let filteredAssignClient = this.auxtTypes.filter(element => element.time === event);
          this.types = [...filteredAssignClient]
          break;
      case 'SIN ACTIVIDAD ASIGNADA':
        this.types = [...defaultNoAct]
        break;
      case 'ORDINARIO: OTRAS ACTIVIDADES 2BCORE':
        this.types = [...defaultNoAct]
        break;
      case 'ORDINARIO: CURSOS, CERTIFICACIONES':
        this.types = [...defaultCursos, ...defaultAlianzas, ...this.auxtTypes]
        break;
      case 'ORDINARIO: EVENTOS':
        this.types = [...defaultAlianzas, ...this.auxtTypes]
        break;

      case 'ORDINARIO: OPERACIÓN DE AREA':
        this.types = [...this.auxtTypes, ...defaultActArea]
        break;
      case 'ORDINARIO: GESTIÓN DE EQUIPOS(LÍDERES)':
        this.types = [...this.auxtTypes, ...defaultManageTeams]
        break;
      default:
        let filteredSelection = this.auxtTypes.filter(element => element.time === event);
        this.types = [...this.auxtTypes]

    }
    this.registryForm.get('code').setValue(null);
    this.registryForm.get('code').updateValueAndValidity()

  }
  private validHoursMinutes() {
    return (formGroup: FormGroup): ValidationErrors | null => {
      const hours = Number(formGroup.get('hours')?.value ?? 0);
      const minutes = Number(formGroup.get('mins')?.value ?? 0);

      return hours > 0 || minutes > 0 ? null : { validHoursMinutes: true };
    };
  }

  private evaluateTime() {
    const timeParam = this.route.snapshot.paramMap.get('time') ?? '';
    if (timeParam.length !== 13) return;

    const time = Number(timeParam ?? 0);
    if (time <= 0) return;

    const newDate = new Date(time);
    if (isFuture(newDate)) return;

    this.activityDate = new Date(time);
  }

  private loadActivityForm(activityId: string) {
    this.logService.getOne(activityId).subscribe(activity => {
      if (activity) {
        this.isEdit = true
        this.registryForm.patchValue({
          activity: activity.time,
          code: activity.oportunityCode,
          mins: activity.minutes,
          hours: activity.hours,
          description: activity.activities,
          status: activity.status,
        });
        this.registryForm.updateValueAndValidity();

        this.activityDate = new Date(activity.date);

        this.commentsExample = {
          id: activityId,
          type: 'activity',
          comments: activity.comments ? activity.comments : [],
          idUser: sessionStorage.getItem('idEmp')
        }

      } else {
        this.router.navigateByUrl(CRUMBS.colaboratorRegisterActivity.route);
      }
    })


  }

  private loadTableActivities() {
    this.timeAccumulated = {
      hours: 0,
      minutes: 0
    }
    this.logService.getLogsByCollaborator(this.idUser).subscribe(activitiesLog => {
      if (activitiesLog) {
        this.activities = activitiesLog.slice()
          .filter((activity) => isSameDay(new Date(activity.date), this.activityDate))
          .filter(log => {
            // Verificamos si 'time' NO incluye las palabras a excluir
            const incidence = INCIDENCES.find((incidence) => incidence.value == log.time.toLowerCase());
            return !incidence;
          })
          .map((activity) => {
            let hours = Number(activity.hours) + this.timeAccumulated.hours;
            let minutes = Number(activity.minutes) + this.timeAccumulated.minutes;
            if (minutes >= 60) {
              hours += 1;
              minutes -= 60;
            }
            this.timeAccumulated = { hours, minutes }

            return {
              date: activity.date,
              activity: activity.time,
              type: activity.oportunity,
              duration: {
                hours: activity.hours,
                minutes: activity.minutes,
              },
              status: activity.status,
              description: activity.activities,
              id: activity._id
            };
          });
        this.activitiesDataSource = new MatTableDataSource(this.activities);
        this.card = this.activities[this.currentCard]; // Mobile
      }
    })
  }

  public registryHasError(controlName: string) {
    return this.registryForm.controls[controlName]?.invalid && (this.registryForm.controls[controlName]?.dirty || this.registryForm.controls[controlName]?.touched);
  }

  public managementHours(incrementValue: 1 | -1 | 0 = 0) {
    let newValue = Number(this.registryForm.controls['hours'].value ?? 0) + incrementValue;

    if (newValue < 0) return;

    if (newValue >= 24) {
      newValue = 24;
      this.registryForm.controls['mins'].setValue(0);
      this.registryForm.controls['mins'].disable();
    } else if (this.registryForm.controls['mins'].disabled) {
      this.registryForm.controls['mins'].enable();
    }
    this.registryForm.controls['hours'].setValue(newValue);
  }

  public managementMinutes(incrementValue: 1 | -1 | 0 = 0) {
    let newValue = Number(this.registryForm.controls['mins'].value ?? 0) + incrementValue;

    if (newValue < 0) return;

    if (newValue >= 60) {
      newValue = 0;
      this.managementHours(1)
    }
    this.registryForm.controls['mins'].setValue(newValue);
  }

  public sendActivity(formDirective: FormGroupDirective) {
    const formValue = this.registryForm.value;

    let oportunity = this.auxtTypes.filter(a => {
      return a.code === this.registryForm.get('code')?.value
    })
    if (oportunity.length === 0) {
      let auxConst = [...defaultActArea, ...defaultManageTeams, ...defaultNoAct];
      let actCode = auxConst.find((op) => op.code === this.registryForm.get('code')?.value);
      oportunity.push(actCode);

    }
    let newActivity = {
      collaborator: this.idUser,
      date: this.activityDate,
      time: formValue.activity,
      oportunity: oportunity[0].name,
      oportunityCode: formValue.code,
      activities: formValue.description,
      hours: Number(formValue.hours) ?? 0,
      minutes: Number(formValue.mins) ?? 0,
      status: 'pending'
    }

    formDirective.resetForm();
    this.registryForm.reset();
    this.registryForm.patchValue({
      mins: 0,
      hours: 0,
    });

    if (this.isEdit) {
      const activityUpdate = {
        id: this.route.snapshot.paramMap.get('id'),
        body: newActivity
      }
      this.logService.updateLogs(activityUpdate).subscribe(res => {
        if (res) {
          this.loadTableActivities();
          const dialogRef = this.dialog.open(ConfirmComponent, {
            width: '464px',
            data: {
              icon: 'status_approved',
              warn: false,
              title: 'Actividad actualizada',
              description: 'Tu reporte de horas ha sido actualizado. ¿Deseas registrar otra actividad?',
              close: 'No agregar',
              confirm: 'Sí, agregar otra'
            } as ConfirmDialogData,
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result == true) {
              this.router.navigateByUrl('/register');
            } else {
              this.router.navigateByUrl('/hours-employee');
            }
          });
        }
      })


    } else {
      this.logService.createLog(newActivity).subscribe(res => {
        if (res) {
          // this.activities.push(newActivity)
          this.loadTableActivities();
          const dialogRef = this.dialog.open(ConfirmComponent, {
            width: '464px',
            data: {
              icon: 'status_approved',
              warn: false,
              title: 'Actividad enviada',
              description: 'Tu reporte de horas ha sido actualizado. ¿Deseas registrar otra actividad?',
              close: 'No agregar',
              confirm: 'Sí, agregar otra'
            } as ConfirmDialogData,
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result == false) {
              this.router.navigateByUrl('/hours-employee');
            }
          });
        }
      })
    }


  }

  async canDeactivate(): Promise<boolean> {
    if (this.registryForm.dirty) {
      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: '464px',
        data: {
          icon: 'status_question',
          warn: false,
          title: '¿Estás seguro?',
          description: 'Al cancelar el registro de esta actividad, no podrás guardar los cambios.',
          close: 'Salir sin guardar',
          confirm: 'Seguir editando'
        } as ConfirmDialogData,
      });
      const response = await dialogRef.afterClosed().toPromise();
      return response === false;
    } else {
      return true;
    }
  }

  public changeCard(val: 1 | -1) {
    const indexCard = val + this.currentCard;
    const card = this.activities[indexCard];
    if (card) {
      this.currentCard = indexCard;
      this.card = card;
    }
  }
}